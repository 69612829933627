<template>
  <div>
    <v-card
      :loading="carregandoBuscar"
      elevation="1"
    >
      <v-card-title class="grey lighten-3 display-1">
        # {{ naturezaOcorrencia.id || '--' }}
      </v-card-title>
      <v-divider />
      <validation-observer ref="observer">
        <v-form class="mt-4">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <validation-provider
                  v-slot="{ errors }"
                  vid="descricao"
                  name="Descrição"
                >
                  <v-text-field
                    v-model="naturezaNova.descricao"
                    outlined
                    dense
                    hide-details="auto"
                    :disabled="carregandoBuscar"
                    label="Descrição"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row
              class="pt-0"
            >
              <v-col>
                <v-switch
                  v-model="naturezaNova.status"
                  :disabled="carregandoBuscar"
                  hide-details="auto"
                  label="Ativo"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </validation-observer>
      <v-card-actions>
        <v-row>
          <v-col
            cols="12"
            class="text-left"
          >
            <btn-salvar
              :carregando="carregandoSalvar"
              @click="$emit('salvar', naturezaNova)"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import _ from 'lodash'

  export default {

    props: {
      validacaoFormulario: {
        type: Object,
        default: () => { return {} },
      },
      carregandoSalvar: {
        type: Boolean,
        default: false,
      },
      carregandoBuscar: {
        type: Boolean,
        default: false,
      },
      naturezaOcorrencia: {
        type: Object,
        default: () => { return {} },
      },
    },

    data () {
      return {
        naturezaNova: {
          descricao: null,
          status: '1',
        },
      }
    },

    watch: {
      validacaoFormulario (val) {
        if (!val) return

        return this.$refs.observer.setErrors(val)
      },

      naturezaOcorrencia (val) {
        if (_.isEmpty(val)) return false

        this.naturezaNova = val
      },
    },

    methods: {

    },

  }
</script>
